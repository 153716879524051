import React, { useEffect } from 'react'
import FullStory, { identify } from 'react-fullstory'

import useUserData from 'components/common/hooks/useUserData'
import { FULLSTORY_ORG_ID } from 'constants/static'

import useClientConfig from './hooks/useClientConfig'

const FS = () => {
  const { enableSessionTracking } = useClientConfig()
  const user = useUserData()

  useEffect(() => {
    if (!enableSessionTracking || !user || !user.id) {
      return
    }
    identify(user.id?.toString(10), {
      displayName: user.fullName,
      email: user.email,
      clientId: user.clientId,
    })
  }, [user, enableSessionTracking])

  return enableSessionTracking && FULLSTORY_ORG_ID ? (
    <FullStory org={FULLSTORY_ORG_ID} />
  ) : (
    <></>
  )
}

export { FS as FullStory }
