import { GRAPHQL_INITIAL_STATE } from 'constants/static'
import { GET_LOCAL_CACHED_DATA } from 'graphql/queries'

export const resetCacheAndSetInitialValues = (client: any) => {
  client.cache.reset()
  // client.writeData(GRAPHQL_INITIAL_STATE);
  client.writeQuery({
    query: GET_LOCAL_CACHED_DATA,
    data: GRAPHQL_INITIAL_STATE,
  })
  // client.writeData({ data: { isLoggedIn: false } })
  const data = client.readQuery({ query: GET_LOCAL_CACHED_DATA })
  client.writeQuery({
    query: GET_LOCAL_CACHED_DATA,
    data: { ...data, ...{ isLoggedIn: false } },
  })
}
