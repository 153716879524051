import React, { useEffect } from 'react'

import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react'

type Props = {}
type State = { hasError: boolean }

const ErrorComponent = ({ error }: { error: unknown }) => {
  useEffect(() => {
    console.error('error', error)
  }, [error])

  return <p>Loading failed! Please reload.</p>
}

class ErrorBoundary extends React.Component<Props, State> {
  render() {
    return (
      <SentryErrorBoundary
        fallback={({ error }) => (
          <ErrorComponent error={error}></ErrorComponent>
        )}
      >
        {this.props.children}
      </SentryErrorBoundary>
    )
  }
}

export default ErrorBoundary
