import { useState, useEffect } from 'react'

import { UserManager, User, UserManagerSettings } from 'oidc-client-ts'

const useAuthService = () => {
  const [userManager, setUserManager] = useState<UserManager | null>(null)
  const [oidcUser, setOidcUser] = useState<User | null>(null)
  const [triggerLogin, setTriggerLogin] = useState(false)

  const setOidcCredentials = (settings: UserManagerSettings) => {
    const createdUserManager = new UserManager(settings)
    sessionStorage.setItem('userManagerInstance', JSON.stringify(settings))
    setUserManager(createdUserManager)
  }

  const getUser = async (): Promise<User | null> => {
    if (!userManager) return null

    const currentUser = await userManager.getUser()
    setOidcUser(currentUser)
    return currentUser
  }

  const oidcLogin = () => {
    setTriggerLogin(true)
  }

  const completeLogin = () => {
    if (userManager) {
      return userManager.signinRedirectCallback()
    }
  }

  useEffect(() => {
    const storedManagerString = sessionStorage.getItem('userManagerInstance')
    if (storedManagerString) {
      const storedManager = JSON.parse(storedManagerString)
      setUserManager(new UserManager(storedManager))
    }
  }, [])

  useEffect(() => {
    getUser()
  }, [userManager])

  useEffect(() => {
    if (triggerLogin && userManager) {
      userManager.signinRedirect()
    }
  }, [triggerLogin, userManager])

  return {
    oidcUser,
    setOidcCredentials,
    getUser,
    oidcLogin,
    completeLogin,
    userManager,
  }
}

export default useAuthService
