import { gql } from '@apollo/client'

import { CACHE_KEYS } from 'constants/static'

import {
  FRAGMENT_USER_FIELDS,
  FRAGMENT_TASK_FIELDS,
  FRAGMENT_USER_DATES_ROLES_FIELDS,
  FRAGMENT_TEMPLATE_TASK_FIELDS,
  FRAGMENT_TEMPLATE_CATEGORY_FOLDER,
} from './fragments'

// mutation for deleting a task
export const LOGIN_BY_TOKEN = gql`
  mutation TokenLogin($attributes: UserTokenInput!) {
    ${CACHE_KEYS.TOKEN}(attributes: $attributes) {
      id
    }
  }
`
// mutation for deleting a task
export const LOGIN_BY_CREDS = gql`
  mutation login($attributes: LoginInput!) {
    login(attributes: $attributes) {
      id
      authenticationToken
      isAdmin
      isTemporaryPassword
      resetPasswordToken
    }
  }
`

export const LOGIN_WITH_OKTA_TOKEN = gql`
  mutation OktaTokenLogin($attributes: OktaTokenInput!) {
    oktaTokenLogin(attributes: $attributes) {
      id
      authenticationToken
      isAdmin
    }
  }
`

export const LOGIN_WITH_WORKOS_TOKEN = gql`
  mutation WorkosTokenLogin($attributes: WorkosTokenInput!) {
    workosTokenLogin(attributes: $attributes) {
      id
      authenticationToken
    }
  }
`

export const LOGIN_WITH_WORKOS_REDIRECT_URL = gql`
  mutation WorkosRedirectUrl($attributes: WorkosRedirectUrlInput!) {
    workosRedirectUrl(attributes: $attributes) {
      redirectUrl
    }
  }
`

export const LOGIN_WITH_WORKOS_MAGIC_LINK = gql`
  mutation WorkosMagicLink($attributes: WorkosMagicLinkInput!) {
    workosMagicLink(attributes: $attributes) {
      email
    }
  }
`

export const UPLOAD_LOGO_TO_LOCATION_HIERARCHY = gql`
  mutation UploadLogoToLocationHierarchy(
    $locationHierarchyId: ID!
    $logoFile: Upload!
  ) {
    uploadLogoToLocationHierarchy(
      locationHierarchyId: $locationHierarchyId
      logoFile: $logoFile
    ) {
      logoUrl
    }
  }
`

export const UPLOAD_IMAGE_TO_ACTIVE_STORAGE = gql`
  mutation UploadImageToActiveStorage($imageFile: Upload!) {
    uploadImageToActiveStorage(imageFile: $imageFile) {
      imageUrl
    }
  }
`

export const UPLOAD_FILE_TO_ACTIVE_STORAGE = gql`
  mutation UploadFileToActiveStorage($file: Upload!) {
    uploadFileToActiveStorage(file: $file) {
      fileUrl
    }
  }
`

export const CREATE_LOCATION_HIERARCHY_TREE = gql`
  mutation CreateLocationHierarchyTree($tree: String!) {
    createLocationHierarchyTree(tree: $tree) {
      success
    }
  }
`

// mutation for deleting a location hierarchy
export const DELETE_LOCATION_HIERARCHY = gql`
  mutation DELETE_LOCATION_HIERARCHY($id: ID!) {
    deleteLocationHierarchy(id: $id) {
      id
    }
  }
`

// mutation for deleting a location hierarchy
export const DUPLICATE_LOCATION_AND_TASKS = gql`
  mutation DuplicateLocationAndTasks($id: ID!, $title: String!) {
    duplicateLocationAndTasks(id: $id, title: $title) {
      id
    }
  }
`

export const UPDATE_LOCATION_HIERARCHY = gql`
  mutation UpdateLocationHierarchy($attributes: LocationHierarchyInput!) {
    updateLocationHierarchy(attributes: $attributes) {
      title
      state
    }
  }
`

// mutation for creating a task
export const CREATE_TASK = gql`
  mutation CreateTask($attributes: TaskInput!) {
    createTask(attributes: $attributes) {
      ...TaskFields
    }
  }
  ${FRAGMENT_TASK_FIELDS}
`

export const CREATE_TASKS_FROM_TEMPLATES = gql`
  mutation CreateTasksFromTemplates($attributes: TasksFromTemplatesInput!) {
    createTasksFromTemplates(attributes: $attributes) {
      ...TaskFields
    }
  }
  ${FRAGMENT_TASK_FIELDS}
`

// mutation for updating a task
export const UPDATE_TASK = gql`
  mutation UpdateTask($id: ID!, $locale: String!, $attributes: TaskInput!) {
    updateTask(id: $id, locale: $locale, attributes: $attributes) {
      ...TaskFields
    }
  }
  ${FRAGMENT_TASK_FIELDS}
`

// mutation for creating a task
export const CREATE_TEMPLATE_TASK = gql`
  mutation CreateTemplateTask(
    $attributes: TemplateTaskInput!
    $createDefaultRole: Boolean
  ) {
    createTemplateTask(
      attributes: $attributes
      createDefaultRole: $createDefaultRole
    ) {
      ...TemplateTaskFields
    }
  }
  ${FRAGMENT_TEMPLATE_TASK_FIELDS}
`

// mutation for updating a task
export const UPDATE_TEMPLATE_TASK = gql`
  mutation UpdateTemplateTask(
    $ids: [ID!]!
    $locale: String
    $attributes: TaskInput!
  ) {
    updateTemplateTask(ids: $ids, locale: $locale, attributes: $attributes) {
      ...TemplateTaskFields
    }
  }
  ${FRAGMENT_TEMPLATE_TASK_FIELDS}
`

export const UPDATE_TEMPLATE_TASK_IMAGE = gql`
  mutation UpdateTemplateTaskImage(
    $id: ID!
    $attributes: UpdateTemplateTaskImageInput!
  ) {
    updateTemplateTaskImage(id: $id, attributes: $attributes) {
      id
    }
  }
`

// mutation for deleting a task
export const DELETE_TASK = gql`
  mutation DeleteTask($id: ID!) {
    deleteTask(id: $id) {
      ...TaskFields
    }
  }
  ${FRAGMENT_TASK_FIELDS}
`

// mutation for archiving a task
export const ARCHIVE_TASK = gql`
  mutation ArchiveTask($id: ID!) {
    archiveTask(id: $id) {
      ...TaskFields
    }
  }
  ${FRAGMENT_TASK_FIELDS}
`

// mutation for deleting a task
export const DELETE_TEMPLATE_TASK = gql`
  mutation DeleteTemplateTask($ids: [ID!]!, $deleteAssociatedTasks: Boolean!) {
    deleteTemplateTask(
      ids: $ids
      deleteAssociatedTasks: $deleteAssociatedTasks
    ) {
      id
    }
  }
`

export const UPDATE_TASKS_ORDER = gql`
  mutation UpdateTasksOrder(
    $locationHierarchyId: ID!
    $tasks: [TaskInputReordering!]!
  ) {
    updateTasksOrder(locationHierarchyId: $locationHierarchyId, tasks: $tasks) {
      ...TaskFields
    }
  }
  ${FRAGMENT_TASK_FIELDS}
`

export const UPDATE_TEMPLATE_TASKS_ORDER = gql`
  mutation UpdateTemplateTasksOrder(
    $templateCategoryId: ID!
    $templateTasks: [TaskInputReordering!]!
  ) {
    updateTemplateTasksOrder(
      templateCategoryId: $templateCategoryId
      templateTasks: $templateTasks
    ) {
      id
      title
      description
      taskType
      mediaUrl
    }
  }
`

export const UPDATE_TEMPLATE_CATEGORIES_ORDER = gql`
  mutation UpdateTemplateCategoriesOrder(
    $parentTemplateCategoryId: ID
    $templateCategoriesIds: [ID!]!
  ) {
    updateTemplateCategoriesOrder(
      parentTemplateCategoryId: $parentTemplateCategoryId
      templateCategoriesIds: $templateCategoriesIds
    ) {
      id
      category
    }
  }
`

// mutation for creating a customer
export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($attributes: CustomerInput!) {
    createCustomer(attributes: $attributes) {
      id
      title
    }
  }
`

// mutation for creating a customer
export const PUBLISH_TEMPLATE_TASK = gql`
  mutation PublishTemplateTask($attributes: TemplateTaskInput!) {
    publishTemplateTask(attributes: $attributes) {
      success
    }
  }
`

// mutation for creating a customer
export const DUPLICATE_TEMPLATE_TASKS = gql`
  mutation DuplicateTemplateTasks($attributes: DuplicateTemplateTasksInput!) {
    duplicateTemplateTasks(attributes: $attributes) {
      success
    }
  }
`

export const CREATE_TEMPLATE_CATEGORY = gql`
  mutation CreateTemplateCategory($attributes: TemplateCategoryInput!) {
    createTemplateCategory(attributes: $attributes) {
      id
    }
  }
`

export const UPDATE_TEMPLATE_CATEGORY = gql`
  mutation UpdateTemplateCategory(
    $id: ID!
    $attributes: TemplateCategoryInput!
  ) {
    updateTemplateCategory(id: $id, attributes: $attributes) {
      id
    }
  }
`

export const DELETE_TEMPLATE_CATEGORY = gql`
  mutation DeleteTemplateCategory(
    $id: ID!
    $moveTasksToTemplateCategoryId: ID
    $deleteTasks: Boolean
  ) {
    deleteTemplateCategory(
      id: $id
      moveTasksToTemplateCategoryId: $moveTasksToTemplateCategoryId
      deleteTasks: $deleteTasks
    ) {
      success
      hasTasks
    }
  }
`

export const UPDATE_LOCATION_HIERARCHY_NOTES = gql`
  mutation UpdateLocationHierarchy($attributes: LocationHierarchyInput!) {
    updateLocationHierarchy(attributes: $attributes) {
      notes
    }
  }
`

export const OKTA_CONFIGURATION = gql`
  mutation OktaConfiguration(
    $issuer: String
    $email: String
    $ssoIdentifier: String
  ) {
    oktaConfiguration(
      issuer: $issuer
      email: $email
      ssoIdentifier: $ssoIdentifier
    ) {
      oktaClientId
      issuer
    }
  }
`

export const DUPLICATE_TEMPLATE_TASK = gql`
  mutation DuplicateTemplateTask($attributes: DuplicateTemplateTaskInput!) {
    duplicateTemplateTask(attributes: $attributes) {
      ...TemplateTaskFields
    }
  }
  ${FRAGMENT_TEMPLATE_TASK_FIELDS}
`
export const UPDATE_USER_PASSWORD = gql`
  mutation UpdateUserPassword($id: ID!, $attributes: UpdateUserPasswordInput!) {
    updateUserPassword(id: $id, attributes: $attributes) {
      id
    }
  }
`

export const RESET_PASSWORD_VIA_TOKEN = gql`
  mutation ResetPasswordViaToken(
    $token: String!
    $attributes: UpdateUserPasswordInput!
  ) {
    resetPasswordViaToken(token: $token, attributes: $attributes) {
      id
      authenticationToken
    }
  }
`

export const UPDATE_WELCOME_EMAIL_SETTINGS = gql`
  mutation UpdateClientWelcomeEmail(
    $attributes: ClientWelcomeEmailSettingInput!
  ) {
    updateClientWelcomeEmail(attributes: $attributes) {
      clientId
      text
      daysBeforeStartDate
      emailType
    }
  }
`

export const DUPLICATE_TEMPLATE_CATEGORY = gql`
  mutation DuplicateTemplateCategory(
    $attributes: DuplicateTemplateCategoryInput!
  ) {
    duplicateTemplateCategory(attributes: $attributes) {
      id
    }
  }
`

export const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`

export const TERMINATE_USER = gql`
  mutation TerminateUser($id: ID!) {
    terminateUser(id: $id) {
      id
    }
  }
`

export const UPDATE_PAGE_SECTION = gql`
  mutation UpdatePageSection($attributes: PageSectionInput!) {
    updatePageSection(attributes: $attributes) {
      id
      content
      contentRaw
      sectionType
      hidden
      pictureUrl
      icon1Url
      icon2Url
      icon3Url
      icon4Url
      icon5Url
      icon6Url
      icon7Url
      icon8Url
      icon9Url
      icon10Url
    }
  }
`

export const UPLOAD_SCHEDULES = gql`
  mutation UploadSchedules($attributes: UploadSchedulesInput!) {
    uploadSchedules(attributes: $attributes) {
      channelId
    }
  }
`

export const UPLOAD_TEMPLATE_TASKS = gql`
  mutation UploadTemplateTasks($attributes: UploadTemplateTasksInput!) {
    uploadTemplateTasks(attributes: $attributes) {
      channelId
    }
  }
`

export const CREATE_MULTIPLE_OPTION = gql`
  mutation CreateMultipleOption($attributes: CreateMultipleOptionInput!) {
    createMultipleOption(attributes: $attributes) {
      id
      clientId
      options {
        id
        option
      }
    }
  }
`

export const CREATE_TEMPLATE_ROLE = gql`
  mutation CreateTemplateRole($attributes: TemplateRoleInput!) {
    createTemplateRole(attributes: $attributes) {
      id
      name
    }
  }
`

export const CREATE_DATE_TYPE = gql`
  mutation CreateDateType($attributes: DateTypeInput!) {
    createDateType(attributes: $attributes) {
      id
      name
    }
  }
`

export const CREATE_EMPLOYEE = gql`
  mutation CreateEmployee($attributes: EmployeeInput!) {
    createEmployee(attributes: $attributes) {
      id
      email
      firstName
      lastName
      fullName
      eligibleTemplateCategories {
        id
        category
        internalCategory
      }
    }
  }
`

export const ASSOCIATE_EMPLOYEE = gql`
  mutation AssociateEmployee($attributes: EmployeeInput!) {
    associateEmployee(attributes: $attributes) {
      id
      email
      firstName
      lastName
      fullName
      eligibleTemplateCategories {
        id
        category
        internalCategory
      }
    }
  }
`

export const UPDATE_EMPLOYEE = gql`
  mutation UpdateEmployee($id: ID!, $attributes: UpdateEmployeeInput!) {
    updateEmployee(id: $id, attributes: $attributes) {
      ...UserFields
      ...UserDatesAndRolesFields
      fullName
      eligibleTemplateCategories {
        id
        category
        internalCategory
      }
    }
  }
  ${FRAGMENT_USER_FIELDS}
  ${FRAGMENT_USER_DATES_ROLES_FIELDS}
`
export const UPDATE_EMPLOYEE_FLAG = gql`
  mutation UpdateEmployeeFlag($id: ID!, $attributes: UpdateEmployeeFlagInput!) {
    updateEmployeeFlag(id: $id, attributes: $attributes) {
      id
    }
  }
`

export const CREATE_WORKFLOW = gql`
  mutation CreateWorkflow($attributes: WorkflowInput!) {
    createWorkflow(attributes: $attributes) {
      success
    }
  }
`

export const IMPORT_INTO_WORKFLOW = gql`
  mutation ImportIntoWorkflow($attributes: ImportIntoWorkflowInput!) {
    importIntoWorkflow(attributes: $attributes) {
      id
    }
  }
`

export const UPDATE_WORKFLOW = gql`
  mutation UpdateWorkflow($attributes: WorkflowUpdateInput!) {
    updateWorkflow(attributes: $attributes) {
      success
    }
  }
`

export const UPDATE_TASK_ANALYTICS = gql`
  mutation UpdateTaskAnalytics($taskAnalyticsId: ID!, $reviewed: Boolean!) {
    updateTaskAnalytics(
      taskAnalyticsId: $taskAnalyticsId
      reviewed: $reviewed
    ) {
      success
    }
  }
`

export const UPDATE_TAXONOMY_TERM_HIERARCHY = gql`
  mutation UpdateTaxonomyTermHierarchy($tree: String!, $taxonomyId: ID!) {
    updateTaxonomyTermHierarchy(tree: $tree, taxonomyId: $taxonomyId) {
      success
    }
  }
`
export const UPDATE_TAXONOMY_TERM = gql`
  mutation UpdateTaxonomyTerm($attributes: TaxonomyTermInput!) {
    updateTaxonomyTerm(attributes: $attributes) {
      title
    }
  }
`

export const DELETE_TAXONOMY_TERM = gql`
  mutation DeleteTaxonomyTerm($id: ID!) {
    deleteTaxonomyTerm(id: $id) {
      id
    }
  }
`

export const DELETE_TAXONOMY = gql`
  mutation DeleteTaxonomy($id: ID!) {
    deleteTaxonomy(id: $id) {
      id
    }
  }
`

export const UPDATE_TAXONOMIES_ORDER = gql`
  mutation UpdateTaxonomiesOrder($taxonomies: [TaxonomyTermInput!]!) {
    updateTaxonomiesOrder(taxonomies: $taxonomies) {
      success
    }
  }
`

export const CREATE_SNIPPET = gql`
  mutation CreateSnippet($attributes: SnippetInput!) {
    createSnippet(attributes: $attributes) {
      id
      name
    }
  }
`

export const UPDATE_SNIPPET = gql`
  mutation UpdateSnippet($id: ID!, $value: String!) {
    updateSnippet(id: $id, value: $value) {
      id
      name
    }
  }
`

export const CREATE_AUTHORIZATION_TOKEN = gql`
  mutation CreateAuthorizationToken($attributes: AuthorizationTokenInput!) {
    createAuthorizationToken(attributes: $attributes) {
      success
    }
  }
`

export const REVOKE_AUTHORIZATION_TOKEN = gql`
  mutation RevokeAuthorizationToken($id: ID!) {
    revokeAuthorizationToken(id: $id) {
      success
    }
  }
`

export const CREATE_AUTO_IMPORT_RULE = gql`
  mutation CreateAutoImportRule($attributes: AutoImportRuleInput!) {
    createAutoImportRule(attributes: $attributes) {
      id
    }
  }
`

export const UPDATE_AUTO_IMPORT_RULE = gql`
  mutation UpdateAutoImportRule($id: ID!, $attributes: AutoImportRuleInput!) {
    updateAutoImportRule(id: $id, attributes: $attributes) {
      id
    }
  }
`

export const DELETE_AUTO_IMPORT_RULE = gql`
  mutation DeleteAutoImportRule($id: ID!) {
    deleteAutoImportRule(id: $id) {
      success
    }
  }
`

export const UPDATE_TAXONOMY_IMPORT_ENABLED = gql`
  mutation UpdateTaxonomyImportEnabled($status: Boolean!) {
    updateTaxonomyImportEnabled(status: $status)
  }
`

export const UPDATE_CLIENT_SETTING = gql`
  mutation UpdateClientSetting($attributes: ClientSettingInput!) {
    updateClientSetting(attributes: $attributes)
  }
`

export const CREATE_MERGE_AUTH_TOKEN = gql`
  mutation CreateMergeAuthToken($publicToken: String!) {
    createMergeAuthToken(publicToken: $publicToken) {
      success
    }
  }
`

export const UPSERT_RESOURCE = gql`
  mutation UpsertResource($attributes: ResourceInput!) {
    upsertResource(attributes: $attributes) {
      id
      title
      titleRaw
      url
      position
    }
  }
`

export const DELETE_RESOURCE = gql`
  mutation DeleteResource($id: ID!) {
    deleteResource(id: $id) {
      id
    }
  }
`

export const UPSERT_RESOURCE_SECTION = gql`
  mutation UpsertResourceSection($attributes: ResourceSectionInput!) {
    upsertResourceSection(attributes: $attributes) {
      id
    }
  }
`

export const DELETE_RESOURCE_SECTION = gql`
  mutation DeleteResourceSection($id: ID!) {
    deleteResourceSection(id: $id) {
      id
    }
  }
`

export const UPDATE_ROLE_PERMISSIONS = gql`
  mutation UpdateRolePermissions($roleId: ID!, $permissionsId: [Int!]!) {
    updateRolePermissions(roleId: $roleId, permissionsId: $permissionsId) {
      id
      title
    }
  }
`

export const CREATE_ROLE = gql`
  mutation CreateRole($name: String!) {
    createRole(name: $name) {
      id
      name
    }
  }
`

export const DELETE_ROLE = gql`
  mutation DeleteRole($id: ID!, $newRoleId: ID) {
    deleteRole(id: $id, newRoleId: $newRoleId) {
      id
      name
    }
  }
`

export const UPDATE_ROLE = gql`
  mutation UpdateRole($id: ID!, $name: String!) {
    updateRole(id: $id, name: $name) {
      id
      name
    }
  }
`

export const ADD_TEMPLATE_TASK_TO_USERS = gql`
  mutation AddTemplateTaskToUsers($templateTaskId: ID!, $userIds: [ID!]!) {
    addTemplateTaskToUsers(templateTaskId: $templateTaskId, userIds: $userIds) {
      success
    }
  }
`
export const RESEND_WELCOME_EMAIL = gql`
  mutation ResendWelcomeEmail($userId: ID!, $sendToPersonalEmail: Boolean!) {
    resendWelcomeEmail(
      userId: $userId
      sendToPersonalEmail: $sendToPersonalEmail
    ) {
      success
    }
  }
`

export const UPDATE_NOTIFICATION_DENY_LIST = gql`
  mutation UpdateNotificationDenyList(
    $attributes: [NotificationDenyListInput!]!
  ) {
    updateNotificationDenyList(attributes: $attributes) {
      title
      type
      typeId: id
      denied
    }
  }
`
export const CREATE_TEMPLATE_TAG = gql`
  mutation CreateTemplateTag($attributes: TemplateTagInput!) {
    createTemplateTag(attributes: $attributes) {
      color
      id
      name
    }
  }
`

export const CREATE_TEMPLATE_ROLE_USER = gql`
  mutation CreateTemplateRoleUser($attributes: TemplateRoleUserInput!) {
    createTemplateRoleUser(attributes: $attributes) {
      id
    }
  }
`

export const UPDATE_TEMPLATE_ROLE_USER = gql`
  mutation UpdateTemplateRoleUser($attributes: TemplateRoleUserInput!) {
    updateTemplateRoleUser(attributes: $attributes) {
      id
    }
  }
`

export const UPDATE_TEMPLATE_ROLE_USERS = gql`
  mutation UpdateTemplateRoleUsers($attributes: [TemplateRoleUserInput!]!) {
    updateTemplateRoleUsers(attributes: $attributes) {
      priority
    }
  }
`

export const DELETE_TEMPLATE_ROLE_USER = gql`
  mutation DeleteTemplateRoleUser($id: ID!) {
    deleteTemplateRoleUser(id: $id) {
      success
    }
  }
`

export const SEND_TEMPLATE_TASK_EMAIL_SAMPLE = gql`
  mutation SendTemplateTaskEmailSample($templateTaskId: ID!) {
    sendTemplateTaskEmailSample(templateTaskId: $templateTaskId) {
      success
      toEmail
    }
  }
`

export const SEND_WELCOME_EMAIL_SAMPLE = gql`
  mutation SendWelcomeEmailSample($type: String!) {
    sendWelcomeEmailSample(type: $type) {
      success
      toEmail
    }
  }
`

export const UPDATE_CONTENT_PAGE_SECTIONS_ORDER = gql`
  mutation UpdateContentPageSectionsOrder($contentPageSectionsIds: [ID!]!) {
    updateContentPageSectionsOrder(
      contentPageSectionsIds: $contentPageSectionsIds
    ) {
      id
    }
  }
`

export const UPDATE_CONTENT_PAGE = gql`
  mutation UpdateContentPage($attributes: PageInput!) {
    updatePage(attributes: $attributes) {
      id
    }
  }
`

export const UPDATE_RESOURCE_PAGE_ORDER = gql`
  mutation UpdateResourcePageOrder(
    $sections: [ResourceSectionInputReordering!]!
  ) {
    updateResourcePageOrder(sections: $sections) {
      success
    }
  }
`

export const UPLOAD_ICON_TO_LIBRARY = gql`
  mutation UploadIconToLibrary($attributes: IconInput!) {
    uploadIconToLibrary(attributes: $attributes) {
      id
      name
      url
    }
  }
`
export const DELETE_ICON_LIBRARY = gql`
  mutation DeleteIconLibrary($id: ID!) {
    deleteIconLibrary(id: $id) {
      id
    }
  }
`

export const UPDATE_USER_PREFERENCES = gql`
  mutation UpdateUserPreferences($attributes: UpdateUserPreferencesInput!) {
    updateUserPreferences(attributes: $attributes) {
      preferences {
        showCompletedWorkflow
      }
    }
  }
`
export const ASK_CHAT = gql`
  mutation AskChat($messages: [ChatMessageInput!]!, $type: String) {
    askChat(messages: $messages, type: $type) {
      content
      role
    }
  }
`

export const CREATE_DATE_COHORT = gql`
  mutation CreateDateCohort($attributes: DateCohortInput!) {
    createDateCohort(attributes: $attributes) {
      name
      dateCohortDates {
        id
        toDate
        name
      }
    }
  }
`

export const UPDATE_DATE_COHORT = gql`
  mutation UpdateDateCohort($id: ID!, $attributes: DateCohortInput!) {
    updateDateCohort(id: $id, attributes: $attributes) {
      name
      dateCohortDates {
        id
        toDate
        name
      }
    }
  }
`

export const DELETE_DATE_COHORT = gql`
  mutation DeleteDateCohort($id: ID!) {
    deleteDateCohort(id: $id) {
      success
    }
  }
`

export const SWITCH_CLIENTS = gql`
  mutation SwitchClients($attributes: SwitchClientsInput!) {
    switchClients(attributes: $attributes) {
      success
    }
  }
`

export const SLACK_UNINSTALL = gql`
  mutation SlackUninstall {
    slackUninstall {
      success
    }
  }
`

export const SEND_EMAIL_TASK = gql`
  mutation SendEmailTask($taskId: String!) {
    sendEmailTask(taskId: $taskId) {
      success
    }
  }
`

export const UPSERT_CONTENT_ITEMS_SECTION = gql`
  mutation UpsertContentItemsSection($attributes: ContentItemsSectionInput!) {
    upsertContentItemsSection(attributes: $attributes) {
      id
      title
    }
  }
`
export const UPSERT_CONTENT_ITEM = gql`
  mutation UpsertContentItem($attributes: ContentItemInput!) {
    upsertContentItem(attributes: $attributes) {
      id
      title
      description
      descriptionRaw
      position
      contentItemsSectionId
    }
  }
`

export const DELETE_CONTENT_ITEMS_SECTION = gql`
  mutation DeleteContentItemsSection($id: ID!, $newContentItemsSectionId: ID) {
    deleteContentItemsSection(
      id: $id
      newContentItemsSectionId: $newContentItemsSectionId
    ) {
      success
    }
  }
`

export const UPDATE_CONTENT_ITEMS_SECTIONS_ORDER = gql`
  mutation UpdateContentItemsSectionsOrder(
    $sections: [ContentItemsSectionInputReordering!]!
  ) {
    updateContentItemsSectionsOrder(sections: $sections) {
      success
    }
  }
`

export const DELETE_CONTENT_ITEM = gql`
  mutation DeleteContentItem($id: ID!) {
    deleteContentItem(id: $id) {
      success
    }
  }
`
export const CHECK_EMAIL_LIST = gql`
  mutation CheckEmailList($emails: [String!]!) {
    checkEmailList(emails: $emails) {
      matchingUsers {
        id
        fullName
        email
      }
      missingUserEmails
    }
  }
`

export const BULK_ASSIGN_TEMPLATES = gql`
  mutation BulkAssignTemplates(
    $userIds: [ID!]!
    $templateCategoryIds: [ID!]!
    $option: String
  ) {
    bulkAssignTemplates(
      userIds: $userIds
      templateCategoryIds: $templateCategoryIds
      option: $option
    ) {
      success
    }
  }
`

export const REIMPORT_TEMPLATE_TASK = gql`
  mutation ReimportTemplateTask($id: ID!, $userIds: [ID!], $taskIds: [ID!]) {
    reimportTemplateTask(id: $id, userIds: $userIds, taskIds: $taskIds) {
      success
      __typename
    }
  }
`

export const REVOKE_INTEGRATION_TOKEN = gql`
  mutation RevokeIntegrationToken($type: String!, $owner: String!) {
    revokeIntegrationToken(type: $type, owner: $owner) {
      success
    }
  }
`

export const FAVORITE_INTEGRATION_TOKEN = gql`
  mutation FavoriteIntegrationToken($type: String!, $owner: String!) {
    favoriteIntegrationToken(type: $type, owner: $owner) {
      success
    }
  }
`

export const UPSERT_BLACKOUT_DATES = gql`
  mutation UpsertBlackoutDates($attributes: BlackoutDateInput!) {
    upsertBlackoutDates(attributes: $attributes) {
      success
    }
  }
`

export const UPDATE_CALENDAR_ADDITIONAL_ACCOUNTS = gql`
  mutation UpdateCalendarAdditionalAccounts($emails: [String!]!) {
    updateCalendarAdditionalAccounts(emails: $emails) {
      email
    }
  }
`

export const REIMPORT_TEMPLATE_CATEGORY = gql`
  mutation ReimportTemplateCategory($templateCategoryId: ID!, $userIds: [ID!]) {
    reimportTemplateCategory(
      templateCategoryId: $templateCategoryId
      userIds: $userIds
    ) {
      success
      __typename
    }
  }
`

export const GET_PRESIGNED_UPLOAD_URL = gql`
  mutation GetPresignedUploadUrl($attributes: PreSignedUrlInput!) {
    getPreSignedUploadUrl(attributes: $attributes) {
      url
      headers
      blobId
      signedBlobId
    }
  }
`

export const UPSERT_TEMPLATE_CATEGORY_FOLDER = gql`
  mutation UpsertTemplateCategoryFolder(
    $attributes: TemplateCategoryFolderInput!
  ) {
    upsertTemplateCategoryFolder(attributes: $attributes) {
      ...TemplateCategoryFolderFields
    }
  }
  ${FRAGMENT_TEMPLATE_CATEGORY_FOLDER}
`

export const DELETE_TEMPLATE_CATEGORY_FOLDER = gql`
  mutation DeleteTemplateCategoryFolder($id: ID!) {
    deleteTemplateCategoryFolder(id: $id) {
      success
    }
  }
`

export const UPDATE_TEMPLATE_CATEGORY_FOLDER_ORDER = gql`
  mutation UpdateTemplateCategoryFoldersOrder($ids: [ID!]!) {
    updateTemplateCategoryFoldersOrder(ids: $ids) {
      ...TemplateCategoryFolderFields
    }
  }
  ${FRAGMENT_TEMPLATE_CATEGORY_FOLDER}
`

export const UPDATE_CLIENT_EMAIL_ICONS = gql`
  mutation UpdateClientEmailIcons($attributes: ClientEmailIconsInput!) {
    updateClientEmailIcons(attributes: $attributes) {
      success
    }
  }
`

export const UPSERT_CLIENT_SECRET = gql`
  mutation UpsertClientSecret($attributes: ClientSecretInput!) {
    upsertClientSecret(attributes: $attributes) {
      id
      issuer
      identifier
      secretHint
      data
    }
  }
`

export const UPLOAD_VIDEO_TO_TEMPLATE_TASK = gql`
  mutation UploadVideoToTemplateTask(
    $attributes: UploadVideoToTemplateTaskInput!
  ) {
    uploadVideoToTemplateTask(attributes: $attributes)
  }
`

export const UPLOAD_SCORM_COURSE_TO_TEMPLATE_TASK = gql`
  mutation UploadScormCourseToTemplateTask(
    $attributes: UploadScormCourseToTemplateTaskInput!
  ) {
    uploadScormCourseToTemplateTask(attributes: $attributes) {
      id
      title
      uploadState
    }
  }
`

export const UPDATE_COHORT_DATE_ELIGIBLE_USERS = gql`
  mutation UpdateCohortDateEligibleUsers(
    $dateCohortDateId: ID!
    $dateCohortId: ID!
    $targetDateCohortDateId: ID
  ) {
    updateCohortDateEligibleUsers(
      dateCohortDateId: $dateCohortDateId
      dateCohortId: $dateCohortId
      targetDateCohortDateId: $targetDateCohortDateId
    ) {
      success
    }
  }
`

export const UPSERT_TEMPLATE_TASK_OVERRIDE_RULE = gql`
  mutation UpsertTemplateTaskOverrideRules(
    $rules: [TemplateTaskOverrideRuleInput!]!
    $templateTaskId: ID!
  ) {
    upsertTemplateTaskOverrideRules(
      rules: $rules
      templateTaskId: $templateTaskId
    ) {
      id
    }
  }
`

export const UPSERT_REPORT = gql`
  mutation UpsertReport(
    $id: ID
    $name: String!
    $message: String
    $reportDateSetting: TemplateTaskDateSettingInput
    $config: ReportConfigInput
  ) {
    upsertReport(
      id: $id
      name: $name
      message: $message
      reportDateSetting: $reportDateSetting
      config: $config
    ) {
      id
      name
    }
  }
`

export const DELETE_REPORT = gql`
  mutation DeleteReport($id: ID!) {
    deleteReport(id: $id) {
      success
    }
  }
`

export const DUPLICATE_REPORT = gql`
  mutation DuplicateReport($id: ID!, $name: String!) {
    duplicateReport(id: $id, name: $name) {
      id
    }
  }
`
export const GENERATE_REPORT = gql`
  mutation GenerateReport($id: ID!) {
    generateReport(id: $id) {
      id
    }
  }
`
export const GENERATE_REPORTS = gql`
  mutation GenerateReports($ids: [ID!]!) {
    generateReports(ids: $ids) {
      id
    }
  }
`

export const UPDATE_REPORT_VIEW_CONFIG = gql`
  mutation UpdateReportViewConfig(
    $id: ID!
    $viewConfig: ReportViewConfigInput
  ) {
    updateReportViewConfig(id: $id, viewConfig: $viewConfig) {
      id
      name
    }
  }
`
