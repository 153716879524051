import { GraphQLError } from 'graphql'

export class GraphQLException extends Error {
  public level

  constructor(error: GraphQLError) {
    super(error.message)
    this.name = `GraphQLError: ${error.message}`
    this.level = error?.extensions?.level
  }
}
