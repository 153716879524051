import { TaskTypeEnum } from 'constants/types'

export const CACHE_KEYS = {
  TOKEN: 'authenticationToken',
}

export const LOCAL_STORAGE_KEYS = {
  TOKEN: 'authToken',
  CUSTOMERS_SEARCH_FIELD: 'customersSearchField',
  CUSTOMERS_STATUS_FIELD: 'customersStatusField',
  USER_EMAIL: 'userEmail',
}

export const SUPPORTED_VIDEO_TYPES = {
  MP4: 'mp4',
  MOV: 'mov',
}

export const PASSWORD_MIN_LENGTH = 6

export const DEFAULT_RRULE = `DTSTART:${new Date(
  +new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000),
)
  .toISOString()
  .substring(0, 10)
  .replace(/-/g, '')}T010000Z
  RRULE:FREQ=DAILY;INTERVAL=1`

export const GRAPHQL_INITIAL_STATE = {
  hasError: false,
  hasSuccess: false,
  isLoggedIn: !!localStorage.getItem('authToken'),
}

export const DOCUSIGN_INTEGRATION_KEY =
  process.env.REACT_APP_DOCUSIGN_INTEGRATION_KEY
export const DOCUSIGN_BASE_URL = process.env.REACT_APP_DOCUSIGN_BASE_URL
export const BACKEND_URL = process.env.REACT_APP_GRAPHQL_BACKEND_URL

export const GOOGLE_CALENDAR_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_ID

export const OUTLOOK_CLIENT_ID = process.env.REACT_APP_OUTLOOK_CLIENT_ID
export const ZOOM_CLIENT_ID = process.env.REACT_APP_ZOOM_CLIENT_ID

export const DOCEBO_CLIENT_ID = process.env.REACT_APP_DOCEBO_CLIENT_ID
export const GOTO_CLIENT_ID = process.env.REACT_APP_GOTO_CLIENT_ID

export const TANGELO_OKTA_CLIENT_ID =
  process.env.REACT_APP_TANGELO_OKTA_CLIENT_ID
export const TANGELO_OKTA_DOMAIN = process.env.REACT_APP_TANGELO_OKTA_DOMAIN
export const TANGELO_OKTA_ISSUER_URL = `https://${TANGELO_OKTA_DOMAIN}`

export const OKTA_REDIRECT_PATH = '/sso-login'
export const CROSS_DOMAIN_STORAGE_URL =
  process.env.REACT_APP_CROSS_DOMAIN_STORAGE_URL

export const FULLSTORY_ORG_ID = process.env.REACT_APP_FULLSTORY_ORG_ID

export const TASK_TYPES = [
  {
    title: 'Complete',
    taskType: TaskTypeEnum.TOGGLE,
  },
  {
    title: 'Email',
    taskType: TaskTypeEnum.EMAIL,
  },
  {
    title: 'Calendar Event',
    taskType: TaskTypeEnum.CALENDAR_EVENT,
  },
  {
    title: 'Info',
    taskType: TaskTypeEnum.INFO,
  },
  {
    title: 'Multiple Choice',
    taskType: TaskTypeEnum.MULTIPLE_OPTION,
  },
  {
    title: 'Comment',
    taskType: TaskTypeEnum.TEXT,
  },
  {
    title: 'Upload Media',
    taskType: TaskTypeEnum.UPLOAD_MEDIA,
  },
  {
    title: 'DocuSign',
    taskType: TaskTypeEnum.DOCUSIGN,
  },
  {
    title: 'E-Sign',
    taskType: TaskTypeEnum.SIGN,
  },
  {
    title: 'Quiz',
    taskType: TaskTypeEnum.QUIZ,
  },
  {
    title: 'Survey',
    taskType: TaskTypeEnum.SURVEY,
  },
  {
    title: 'Scorecard',
    taskType: TaskTypeEnum.SCORECARD,
  },
  {
    title: 'Slack',
    taskType: TaskTypeEnum.SLACK_MESSAGE,
  },
  {
    title: 'Send Safely',
    taskType: TaskTypeEnum.SEND_SAFELY,
  },
  {
    title: 'SMS',
    taskType: TaskTypeEnum.SMS,
  },
  {
    title: 'Video',
    taskType: TaskTypeEnum.VIDEO,
  },
  {
    title: 'Docebo',
    taskType: TaskTypeEnum.DOCEBO,
  },
  {
    title: 'SCORM Course',
    taskType: TaskTypeEnum.SCORM_COURSE,
  },
]

export const LOCATION_HIERARCHY_STATES = [
  {
    value: 'ACTIVE',
    label: 'active',
  },
  {
    value: 'INACTIVE',
    label: 'inactive',
  },
  {
    value: 'PROFILE',
    label: 'profile',
  },
]

export const DATE_FORMAT_SHORT = 'yyyy-MM-dd'
export const STATIC_ROLES = ['Manager', 'Employee']

export const VALIDATION_SETTINGS = {
  EMAIL: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: 'Invalid email address',
  },
}

export const AVAILABLE_LOCALES = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'French',
    value: 'fr',
  },
  {
    label: 'French (Canada)',
    value: 'fr-CA',
  },
  {
    label: 'Spanish',
    value: 'es',
  },
  {
    label: 'Portuguese',
    value: 'pt',
  },
  {
    label: 'Japanese',
    value: 'ja',
  },
  {
    label: 'German',
    value: 'de',
  },
  {
    label: 'Chinese (China)',
    value: 'zh-CN',
  },
  {
    label: 'Chinese (Taiwan)',
    value: 'zh-TW',
  },
]

export const DEFAULT_DAYS_DATE_SETTINGS = 7
