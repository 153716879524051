import React, { Suspense } from 'react'
import { Route, Router, Switch } from 'react-router-dom'

import { createBrowserHistory } from 'history'

import { Loader } from 'components/Index'
import ErrorBoundary from 'components/common/ErrorBoundary'
import SSOLoginRedirect from 'components/login/sso/SSOLoginRedirect'
import { OKTA_REDIRECT_PATH } from 'constants/static'
import lazyWithRetry from 'utils/lazyWithRetry'

import { PUBLIC_ROUTE } from './routes.constants'

const Dashboard = lazyWithRetry(
  () => import('./components/dashboard/Dashboard'),
)

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LOGIN,
    exact: true,
    component: lazyWithRetry(() => import('./components/login/Login')),
  },
  {
    path: PUBLIC_ROUTE.SSO,
    exact: true,
    component: lazyWithRetry(
      () => import('./components/login/sso/SingleSignOnRedirect'),
    ),
  },
  {
    path: PUBLIC_ROUTE.PASSWORD_RESET,
    exact: true,
    component: lazyWithRetry(
      () => import('./components/password-reset/PasswordResetPage'),
    ),
  },
  {
    path: PUBLIC_ROUTE.OIDC_LOGIN,
    exact: true,
    component: lazyWithRetry(() => import('./components/login/sso/OidcLogin')),
  },
]

const history = createBrowserHistory()

export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router history={history}>
          <Switch>
            <Route
              path={OKTA_REDIRECT_PATH}
              component={SSOLoginRedirect}
              exact
            />
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <Route path="/dashboard">
              <Dashboard />
            </Route>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  )
}
