import React from 'react'

import Rodal from 'rodal'
import 'rodal/lib/rodal.css'
import styled from 'styled-components'

import OrdableList from 'components/analytics/new-reports/OrdableList'
import { COLORS } from 'constants/style'

type RodalProps = {
  onClose: () => void
  children: React.ReactNode
  visible?: boolean
  width?: number
  height?: number
  measure?: string
  onAnimationEnd?: () => void
  showMask?: boolean
  closeOnEsc?: boolean
  closeMaskOnClick?: boolean
  showCloseButton?: boolean
  animation?: string
  enterAnimation?: string
  leaveAnimation?: string
  duration?: number
  className?: string
  customStyles?: object
  customMaskStyles?: object
  id?: string
}

type CustomModalProps = RodalProps & {
  title?: React.ReactNode | string
  customStyles?: any
  noPaddingOnBody?: boolean
}

const CustomModal = ({
  title,
  children,
  customStyles,
  noPaddingOnBody,
  ...rest
}: CustomModalProps) => {
  return (
    <StyledRodal
      height={'auto'}
      {...rest}
      customStyles={
        customStyles || {
          bottom: 'auto',
          top: '50%',
          maxHeight: 'calc(100vh - 20px)',
          transform: 'translateY(-50%)',
        }
      }
    >
      <ModalTitle>{title}</ModalTitle>
      <ModalContent
        noPaddingOnBody={!!noPaddingOnBody}
        className="custom-modal-content"
      >
        {children}
      </ModalContent>
    </StyledRodal>
  )
}

const ModalTitle = styled.h2`
  margin: 0;
  font-weight: 600;
  color: #404040;
  font-size: 18px;
  min-height: 30px;
  margin-bottom: 20px;
  color: ${COLORS.NAVY_BLUE};
  padding: 30px 30px 0px 30px;
  text-wrap: wrap;
`
const ModalContent = styled.div<{ noPaddingOnBody: boolean }>`
  flex: 1;
  position: relative;
  overflow: auto;
  padding: ${(props) => (props.noPaddingOnBody ? '0px' : '0px 30px 30px 30px')};
`

const StyledRodal = styled(Rodal)`
  .rodal-dialog {
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 0px;
  }

  .rodal-close {
    top: 30px;
    right: 30px;
  }
`

export default CustomModal
