import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useMutation } from '@apollo/client'
import { captureException } from '@sentry/react'

import { Loader } from 'components/Index'
import useAuthService from 'components/common/hooks/useAuthService'
import useLogin from 'components/common/hooks/useLogin'
import { LOCAL_STORAGE_KEYS } from 'constants/static'
import { LOGIN_WITH_OKTA_TOKEN } from 'graphql/mutations'

const SSOLoginRedirect: React.FC = () => {
  const { completeLogin, userManager } = useAuthService()
  const history = useHistory()
  const { storeLoginToken } = useLogin()

  const [oktaTokenLogin, { loading: oktaLoading }] = useMutation(
    LOGIN_WITH_OKTA_TOKEN,
    {
      onError: (error) => {
        if (!!error.graphQLErrors[0]) {
          history.push({
            pathname: '/',
            search: `?error=${error.graphQLErrors[0].message}`,
          })
        }
      },
    },
  )

  useEffect(() => {
    const completeLoginCallback = async () => {
      if (!userManager?.settings?.client_id) {
        return
      }

      try {
        const user = await completeLogin()
        const email = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_EMAIL)
        if (!user?.access_token) {
          return
        }

        const oktaTokenLoginResult = await oktaTokenLogin({
          variables: {
            attributes: {
              accessToken: user.access_token,
              idToken: user?.id_token,
              email,
              isMobile: false,
            },
          },
        })

        if (!oktaTokenLoginResult?.data?.oktaTokenLogin) {
          return
        }

        const { data: result } = oktaTokenLoginResult

        const token = result?.oktaTokenLogin?.authenticationToken
        storeLoginToken(token)
        history.push('/dashboard')
      } catch (error) {
        captureException(error)
        history.push({
          pathname: '/',
          search: '?error=Something went wrong, signing in.',
        })
        // Handle errors or redirect to an error page
      }
      history.push('/') // Redirect to the home page
    }

    completeLoginCallback()
  }, [userManager])

  return (
    <div>
      <Loader />
    </div>
  )
}

export default SSOLoginRedirect
