import { useContext } from 'react'

import { useApolloClient } from '@apollo/client'

import { LOCAL_STORAGE_KEYS } from 'constants/static'
import AppContext from 'contexts/appContext'
import { GET_LOCAL_CACHED_DATA } from 'graphql/queries'

const useLogin = () => {
  const { saveCrossDomainToken } = useContext(AppContext)
  const client = useApolloClient()

  const storeLoginToken = (token: string) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, token)
    saveCrossDomainToken(token)
    const data = client.readQuery({ query: GET_LOCAL_CACHED_DATA })
    client.writeQuery({
      query: GET_LOCAL_CACHED_DATA,
      data: { ...data, ...{ isLoggedIn: true, hasError: false } },
    })
  }

  return {
    storeLoginToken,
  }
}

export default useLogin
