import { useMemo } from 'react'

import { useQuery, WatchQueryFetchPolicy } from '@apollo/client'

import { GET_CURRENT_USER } from 'graphql/queries'

type Props = {
  fetchPolicy?: WatchQueryFetchPolicy
}

const useUserData = (
  { fetchPolicy }: Props = { fetchPolicy: 'cache-only' },
) => {
  const { data, loading } = useQuery<{ user: User }>(GET_CURRENT_USER, {
    fetchPolicy,
  })
  return useMemo(() => {
    if (loading) {
      return {} as User
    }

    return data?.user || ({} as User)
  }, [data, loading])
}

export default useUserData
