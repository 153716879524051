import React, { useState, useEffect } from 'react'

import { useQuery } from '@apollo/client'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { GET_TEMPLATE_CATEGORIES } from 'graphql/queries'

interface TemplateCategoryDropDownPropsType {
  onCategoryChange: any
  showInactive?: boolean
}

interface TemplateCategoriesData {
  templateCategories: TemplateCategory[]
}

interface TemplateCategoriesVars {}

const TemplateCategoryDropDown = (props: TemplateCategoryDropDownPropsType) => {
  const { onCategoryChange, showInactive } = props
  const [templateCategories, setTemplateCategories] = useState<
    TemplateCategory[]
  >([])
  const [value, setValue] = React.useState<TemplateCategory>()
  const [inputValue, setInputValue] = React.useState('')

  const {
    loading: loadingGetTemplateCategories,
    error: errorGetTemplateCategories,
    data: dataGetTemplateCategories,
  } = useQuery<TemplateCategoriesData, TemplateCategoriesVars>(
    GET_TEMPLATE_CATEGORIES,
  )

  useEffect(() => {
    if (
      dataGetTemplateCategories &&
      dataGetTemplateCategories.templateCategories
    ) {
      const { templateCategories } = dataGetTemplateCategories
      const filteredTemplateCategories = showInactive
        ? templateCategories
        : templateCategories.filter(
            (templateCategory) => !templateCategory.inactive,
          )
      setTemplateCategories(filteredTemplateCategories)
      setValue(filteredTemplateCategories[0])
    }
  }, [dataGetTemplateCategories])

  return (
    <Autocomplete
      value={value}
      onChange={(event: any, newValue: TemplateCategory | null) => {
        setValue(newValue || undefined)
        onCategoryChange(newValue?.id)
      }}
      fullWidth
      inputValue={inputValue}
      onInputChange={(event: any, newInputValue: any) => {
        setInputValue(newInputValue)
      }}
      id="template-categories"
      options={templateCategories}
      getOptionLabel={(option) => option.internalCategory}
      renderInput={(params) => (
        <TextField {...params} label="Templates" variant="outlined" />
      )}
    />
  )
}

export default TemplateCategoryDropDown
