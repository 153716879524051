import { createTheme, adaptV4Theme } from '@mui/material';

import { COLORS } from 'constants/style'

export default function createCustomMuiTheme() {

  const labelStyle = {
    root: {
      fontWeight: 500,
      fontSize: 16,
      color: COLORS.NAVY_BLUE,
    },
    label: {
      fontWeight: 500,
      fontSize: 16,
      color: COLORS.NAVY_BLUE,
    },
  }

  return createTheme(adaptV4Theme({
    palette: {
      primary: {
        main: `${COLORS.TURQUOISE}`,
        contrastText: '#fff',
      },
      text: {
        primary: COLORS.LIGHT_GREY3,
      },
      action: {
        disabledBackground: '#CCCCD6',
      },
    },
    typography: {
      fontFamily: ['"Poppins"', 'sans-serif'].join(','),
    },

    overrides: {
      MuiChip: {
        root: {
          backgroundColor: '#F5F8FD',
          border: '1px solid #D4DBE8',
          borderRadius: '10px',
        },
      },

      MuiButton: {
        root: {
          textTransform: 'none',
          fontSize: '16px',
          boxShadow: 'none',
          fontWeight: 'normal',
        },
        contained: {
          background: COLORS.BLUE_GREEN,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '&:focus': {
            boxShadow: 'none',
            outline: 'none',
          },
        },
        outlined: {
          background: 'white',
          border: `1px solid ${COLORS.TURQUOISE}`,
          boxSizing: 'border-box',
          borderRadius: '8px',
          color: COLORS.GREEN_DARK,
          fontWeight: 600,
          height: 48,
          padding: 12,
        },
        containedSecondary: {
          backgroundColor: '#EEF4FF',
          color: '#7C8DA3',
          border: 'none',
          '&:hover': {
            boxShadow: 'none',
            border: 'none',
            backgroundColor: '#dadfe9',
          },
          '&:focus': {
            boxShadow: 'none',
            outline: 'none',
            border: 'none',
          },
        },
      },
      MuiIconButton: {
        root: {
          color: COLORS.GRAY_BLUE,
        },
      },
      MuiFormControlLabel: labelStyle,
      MuiFormLabel: {...labelStyle, root: {...labelStyle.root, paddingRight: '5px'}},
      MuiInputLabel: {
        outlined: {
          fontSize: 14,
          color: COLORS.NAVY_BLUE,
          transform: 'translate(0, 0) scale(1)',
        },
        shrink: {
          transform: 'translate(0, 0) scale(1) !important',
        },
        sizeSmall: {
          transform: 'translate(0, 0) scale(1) !important',
        },
      },
      MuiFormControl: {
        root: {},
      },
      MuiTabs: {
        root: {
          minHeight: 36,
        },
        indicator: {
          display: 'none',
        },
      },

      MuiTab: {
        root: {
          minHeight: 36,
          minWidth: '0 !important',
          padding: '6px 11px',
          textTransform: 'none',
          fontSize: 18,
          fontWeight: 600,
        },
        textColorInherit: {
          color: COLORS.GRAY_BLUE,
          borderRadius: 10,
          margin: '0 5px',
          '&.Mui-selected': {
            color: '#242953cc',
            backgroundColor: '#F2F7FF',
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          borderColor: COLORS.LIGHT_GRAY_BLUE,
          'label + &': {
            marginTop: 24,
          },
          '&:hover $notchedOutline': {
            borderColor: COLORS.GRAY_BLUE,
          },
          '&& legend': {
            width: 0,
          },
          '&.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            paddingTop: 2,
            paddingBottom: 2,
            minHeight: 40,
          },
          '&.Mui-focused $notchedOutline': {
            borderWidth: 1,
          },
          '&.Mui-disabled $notchedOutline': {
            borderColor: '#CCCCD6',
          },
        },
        sizeSmall: {
          'label + &': {
            marginTop: 24,
          },
        },
        inputSizeSmall: {
          paddingTop: 12,
          paddingBottom: 11,
          'label + &': {
            marginTop: 10,
          },
        },
        input: {
          fontSize: 14,
          '&& :placeholder': {
            color: 'red',
          },
          padding: '12px 14px 11.5px',
        },
        notchedOutline: {
          borderColor: COLORS.LIGHT_GRAY_BLUE,
          borderRadius: '4px',
        },
      },
      MuiInputAdornment: {
        root: {
          color: COLORS.GRAY_BLUE,
        },
      },
      MuiInput: {
        root: {
          borderColor: COLORS.LIGHT_GRAY_BLUE,
          fontSize: '14px',
        },
      },
      MuiTableHead: {
        root: {
          '&& th': {
            border: 'none',
            color: '#404040',
            paddingBottom: '13px',
            fontSize: '12px',
            textTransform: 'uppercase',
          },
        },
      },
      MuiTable: {
        root: {
          borderCollapse: 'separate',
          color: COLORS.LIGHT_GREY3,
        },
      },
      MuiTableCell: {
        head: {
          fontWeight: 'bold',
        },
        body: {
          color: COLORS.NAVY_BLUE,
          fontSize: 16,
        },
        stickyHeader: {
          backgroundColor: COLORS.WHITE,
        },
      },
      MuiPaper: {
        elevation1: {
          background: '#FFFFFF',
          border: '1px solid #d5e5f4',
          boxSizing: 'border-box',
          boxShadow: '0px 4px 24px rgba(207, 207, 207, 0.25)',
          borderRadius: '7px',
        },
      },
      MuiTableBody: {
        root: {
          background: COLORS.WHITE,
          borderCollapse: 'separate',
          '&& td': {
            borderTop: 'solid 1px #DFE6ED',
            borderBottom: 'none',
          },
          '&& tr:last-child td': {
            borderBottom: 'solid 1px #DFE6ED',
          },
          '&& td:first-child': {
            borderLeft: 'solid 1px #DFE6ED',
          },
          '&& td:last-child': {
            borderRight: 'solid 1px #DFE6ED',
          },
          '&& tr:first-child td:first-child': {
            borderTopLeftRadius: '10px',
          },
          '&& tr:last-child td:first-child': {
            borderBottomLeftRadius: '10px',
          },
          '&& tr:first-child td:last-child': {
            borderTopRightRadius: '10px',
          },
          '&& tr:last-child td:last-child': {
            borderBottomRightRadius: '10px',
          },
        },
      },
      /* @ts-ignore */
      MuiPickersModal: {
        dialogRoot: {
          '& .MuiDialogActions-root .MuiButtonBase-root': {
            color: 'white',
            backgroundColor: COLORS.TURQUOISE,
          },
          '& .MuiDialogActions-root .MuiButtonBase-root:first-child': {
            backgroundColor: '#EEF4FF',
            color: '#7C8DA3',
            border: 'none',
            marginRight: 10,
          },
        },
      },
      MuiSwitch: {
        root: {
          width: 28,
          height: 16,
          padding: 0,
          display: 'flex',
        },
        switchBase: {
          padding: 2,
          '&.Mui-checked': {
            transform: 'translateX(12px)',
            '& + $track': {
              opacity: 1,
              backgroundColor: COLORS.TURQUOISE,
            },
          },
        },
        thumb: {
          width: 12,
          height: 12,
          boxShadow: 'none',
          color: COLORS.WHITE
        },
        track: {
          borderRadius: 16 / 2,
          opacity: 6,
          backgroundColor: COLORS.GREY1,
        },
        checked: {
    
        },
      },
      MuiTypography: {
        subtitle1: {
          color: '#9b9b9b',
          fontSize: '16px',
          textTransform: 'uppercase',
          fontWeight: 600
        }
      },
      MuiDataGrid: {
        root: {
          border: '0px',
          color: COLORS.NAVY_BLUE,
          '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
        },
      },
    },
    
  }));
}
