import React, { useState, useCallback, memo, useEffect } from 'react'

import { TextField, TextFieldProps } from '@mui/material'
import { TargetType } from 'types'

interface Props extends Omit<TextFieldProps, 'onChange'> {
  onChange?: (value: string) => void
}

// input component
const Input = memo(
  ({
    onChange: onChangeProp,
    value: initialValue,
    type = 'text',
    ...rest
  }: Props) => {
    useEffect(() => {
      if (initialValue === '') {
        setValue('')
      }
    }, [initialValue])

    useEffect(() => {
      setValue(initialValue)
    }, [initialValue])

    const [value, setValue] = useState(initialValue)
    // onchange callback
    const onChange = useCallback(
      ({ target: { value: v } }: TargetType) => {
        setValue(v)
        onChangeProp?.(v)
      },
      [onChangeProp],
    )

    return (
      <TextField
        {...{ value, onChange, type, ...rest }}
        variant="outlined"
        margin="normal"
        fullWidth
      />
    )
  },
)

export default Input
