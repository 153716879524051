// cache keys
const lit = <V extends keyof any>(v: V) => v

export const CACHE_KEYS = {
  TASKS: lit('tasks'),
} as const

export enum TaskTypeEnum {
  PHOTO = 'PHOTO',
  TOGGLE = 'TOGGLE',
  SIGN = 'SIGN',
  TEXT = 'TEXT',
  DOCUSIGN = 'DOCUSIGN',
  OPTION = 'OPTION',
  MULTIPLE_OPTION = 'MULTIPLE_OPTION',
  EMAIL = 'EMAIL',
  CALENDAR_EVENT = 'CALENDAR_EVENT',
  UPLOAD_MEDIA = 'UPLOAD_MEDIA',
  INFO = 'INFO',
  QUIZ = 'QUIZ',
  SURVEY = 'SURVEY',
  SLACK_MESSAGE = 'SLACK_MESSAGE',
  SEND_SAFELY = 'SEND_SAFELY',
  SMS = 'SMS',
  VIDEO = 'VIDEO',
  DOCEBO = 'DOCEBO',
  SCORM_COURSE = 'SCORM_COURSE',
  SCORECARD = 'SCORECARD',
}

export enum CalendarEventTypes {
  NEW_EVENT = 'NEW_EVENT',
  EXISTING_EVENT = 'EXISTING_EVENT',
  GROUP_EVENT = 'GROUP_EVENT',
}

export enum TaskStateEnum {
  COMPLETE = 'COMPLETE',
  CANT_COMPLETE = 'CANT_COMPLETE',
  TODO = 'TODO',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum TaskFrequencyEnum {
  RECURRING = 'RECURRING',
  ONE_TIME = 'ONE_TIME',
}

export enum SnippetTypeEnum {
  STATIC = 'STATIC',
  DYNAMIC = 'DYNAMIC',
  DYNAMIC_EMAIL = 'DYNAMIC_EMAIL',
}

export enum DefaultDateTypeNames {
  START_DATE = 'Start Date',
  TERMINATION_DATE = 'Termination Date',
  WORKFLOW_ASSIGNED_DATE = 'Workflow Assigned Date',
  TASK_ASSIGNED_DATE = 'Task Assigned Date',
}

export enum SlackEventType {
  SEND_MESSAGE = 'SEND_MESSAGE',
  CHANNEL_INVITE = 'CHANNEL_INVITE',
  GROUP_INVITE = 'GROUP_INVITE',
  SEND_MESSAGE_TO_CHANNELS = 'SEND_MESSAGE_TO_CHANNELS',
  KICK_FROM_CHANNEL = 'KICK_FROM_CHANNEL',
}
