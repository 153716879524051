import React, { ReactNode } from 'react'

import { CircularProgress } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

interface ChoiceDialogProps {
  title: string
  modalOpen: boolean
  handleNo: () => void
  handleYes: () => void
  content: string | ReactNode
  isLoading?: boolean
}

const ChoiceDialog = ({
  title,
  handleNo,
  handleYes,
  modalOpen,
  content,
  isLoading,
}: ChoiceDialogProps) => (
  <Dialog
    open={modalOpen}
    onClose={handleNo}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title" data-testid="dialog-title">
      {title}
    </DialogTitle>
    <DialogContent>{content}</DialogContent>
    <DialogActions>
      <Button
        onClick={handleNo}
        color="primary"
        data-testid="dialog-no"
        disabled={isLoading}
      >
        No
      </Button>
      <Button
        onClick={handleYes}
        color="primary"
        autoFocus
        data-testid="dialog-yes"
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress size={24} /> : 'Yes'}
      </Button>
    </DialogActions>
  </Dialog>
)

export default ChoiceDialog
