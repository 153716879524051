import { createContext, useContext } from 'react'

import { Cable } from 'actioncable'

const AppContext = createContext(
  {} as {
    pageTitle: string | { breadcrumbs: string[]; onBack: () => void } | null
    setPageTitle: React.Dispatch<
      React.SetStateAction<
        string | { breadcrumbs: string[]; onBack: () => void } | null
      >
    >
    saveCrossDomainToken: (value: string) => void
    actionCableApp: Cable
    setActionCableApp: React.Dispatch<React.SetStateAction<Cable>>
  },
)

const useAppContext = () => {
  return useContext(AppContext)
}

export default AppContext

export { useAppContext }
