import React, { ReactNode } from 'react'

import ChoiceDialog from './ChoiceDialog'

interface DeleteDialogProps {
  modalOpen: boolean
  handleNo: () => void
  handleYes: () => void
  content: string | ReactNode
  isLoading?: boolean
}

const DeleteDialog = ({
  handleNo,
  handleYes,
  modalOpen,
  content,
  isLoading,
}: DeleteDialogProps) => (
  <ChoiceDialog
    title={'Delete confirmation'}
    handleNo={handleNo}
    handleYes={handleYes}
    modalOpen={modalOpen}
    content={content}
    isLoading={isLoading}
  />
)

export default DeleteDialog
