import React, { useEffect, useMemo, useState } from 'react'

import { Box, Button } from '@mui/material'

import CustomModal from './CustomModal'

const getBaseUrl = () => window.location.protocol + '//' + window.location.host

const AppVersionModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [appVersion, setAppVersion] = useState<null | string>(null)
  const BASE_URL = getBaseUrl()

  const loadAppVersion = () => {
    fetch(`${BASE_URL}/build-version.json?v=${Date.now()}`)
      .then((response) => response.json())
      .then((res) => {
        if (appVersion && res.version && appVersion !== res.version) {
          setIsModalVisible(true)
        }

        setAppVersion(res.version)
      })
      .catch(() => {
        // Ignore for now
      })
  }

  const onVisibilityChange = () => {
    if (document.visibilityState == 'hidden') {
      return
    }
    loadAppVersion()
  }

  const refreshPage = () => {
    window.location.reload()
  }

  useEffect(() => {
    loadAppVersion()
  }, [])

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange)

    return () =>
      document.removeEventListener('visibilitychange', onVisibilityChange)
  }, [appVersion])

  return (
    <CustomModal
      visible={isModalVisible}
      onClose={() => setIsModalVisible(false)}
      width={500}
      title={'New version of Application'}
    >
      <Box pb={2}>
        There is a new version of the Application. To ensure the Application
        works smoothly, please refresh the page.
      </Box>
      <Box>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="button"
          onClick={refreshPage}
        >
          Refresh page
        </Button>
      </Box>
    </CustomModal>
  )
}

export default AppVersionModal
